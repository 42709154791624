import { Controller } from "stimulus"

export default class extends Controller {
  
  connect() {
    // create an anonymous function 
    // https://discourse.stimulusjs.org/t/settimeout-in-stimulus/500
    setTimeout(() => this.close(), 5 * 1000)
  }

  close() {
		// if (typeof(this.elmnt) != 'undefined' && this.elmnt != null) {
    // https://stackoverflow.com/a/42526074
    if (this.elmnt) {
		  // Exists.
		  this.elmnt.classList.add("transition", "duration-500", "ease-in-out", "transform", "opacity-0")
			setTimeout(() => this.elmnt.remove(), 0.5 * 1000)
		}
		
  }

  get elmnt() {
  	return document.getElementById("alert")
  }
}
