import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    // console.log(this.data.get("quotes"))
    this.quote()

    if (this.data.has("refreshInterval")) {
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  quote() {
  	// const quotes = ["Art", "Poetry", "Zen", "Liberation", "Awesome"];
  	var quotes = this.data.get("quotes")
  			quotes = quotes.split(',')
		
		var currentQuote = this.outputTarget.textContent
		var randomQuote = quotes[Math.floor(Math.random() * quotes.length)]
		
		// console.log(currentQuote)

    if (randomQuote == currentQuote) {
    	return this.quote()
    } else {
    	// return randomQuote
    	this.outputTarget.textContent = randomQuote
    }
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.quote()
    }, this.data.get("refreshInterval"))
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}
